import { useState, useEffect } from "react";
import styled from 'styled-components'


import Token from './Token';

import About from './About';
import Starter from './Starter';



import img1 from './assets/x.png';
import img2 from './assets/tele.png';
import img3 from './assets/uni.png';
import img4 from './assets/peng_low.png';
import img5 from './assets/token.png';
import img6 from './assets/about.png';




//import LogoText from 
const Section = styled.section`

 background-color: #0153ff;
height: 100vh;
`
const Menu = styled.ul`
display: flex;
justify-content: right;
align-items: center;
list-style:none;

`

const MenuItem = styled.li`
@media (max-width: 600px) {
  margin-left: 0px;
  margin-right: 5px;
  padding-right: 10px;
}
@media (min-width: 600px) {
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 10px;
}

color: ${props => props.theme.text};
cursor:pointer;
font-family: "Caveat Brush", Trebuchet MS;
font-size: 2em;
transition: all 0.3s ease;


&:hover{
  transform: scale(1.1);
  }

&::after{
  
  content: ' ';
  display: block;
  width: 0%;
  height: 2px;
  background: ${props => props.theme.text};
  transition: width 0.8s ease;
}

&:hover::after{

  width: 100%;
  
}

`

const NavBar = styled.nav`
display: flex;
width: 100vw;
`
const Container = styled.div`
width: 20vw;
 

`
const Container2 = styled.div`
width: 100vw;
margin-top: 10px;
  
`
const Container3 = styled.div`
@media (min-width: 600px) {
  display: none;
}
display: flex;
justify-content: space-between;
justify-content: right;
align-items: center;
padding-right: 10px;
`
const Container4 = styled.div`
@media (max-width: 600px) {
  display: none;
}

display: flex;
margin-top: 10px;
padding-right: 10px;
`
const Subcontainer = styled.div`
margin: 10px;
 
`
const Subcontainer2 = styled.div`
margin: 10px;
margin-top: 0px;
`


const Navigation = () => {

  

  const [_accountcon, setAccountCon] =useState("");
  
    const [start, setStart] = useState(false);
    const [mycitizen, setCitizen] = useState(true);

    const [loot, setLoot] = useState(true);
    const [location, setLocation] = useState(true);

    const enterStart = async () => {
        setStart(false);
        setCitizen(true);
       
 
        setLoot(true);
        setLocation(true);
         //Connection();
        
       
      }
      const enterCitizen = async () => {
        setStart(true);
        setCitizen(false);
 

        setLoot(true);
        setLocation(true);
     
      
        
       
      }
     

      const enterLocation = async () => {
        setStart(true);
        setCitizen(true);
        setLocation(false);
        setLoot(true);
      
      
       
      }







const walletConnect = async () => {
    
  if(typeof window.ethereum !== "undefined"){
  const accounts = await window.ethereum.request({
  method: "eth_requestAccounts",
  });
    setAccountCon(accounts[0]);
   // getData(accounts[0]);
    
  }
  else {
    console.log("???");
  }

  }



       
     
  





  
   
      /*        {data.map(item => {
return (<option value={item.token_id}>Citizen #{item.token_id}</option>)
})}*/


useEffect(() => {

  console.log(_accountcon);
  if (_accountcon > 0){

  walletConnect();
}
// eslint-disable-next-line 
}, [_accountcon]);




//*header för gamla spelet* <Citstats><Citizenstats _XP={totales} _HP={totales1} _Energy={totales2} _name={totales4} _link={linkstring} _Loot={totales3} _BA={totales11} _BL={totales6} _BD={totales5} _BonusA={totales12} _BonusD={totales12} _BonusL={totales12}></Citizenstats></Citstats>



  return (
    <Section>
  
      <NavBar>
        <Container>
      <img src={img4} width="100px" alt="" onClick={enterStart}></img>
      </Container>
      <Container2>
<Container3><Subcontainer2><a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer">
         <img src={img1} width="30px" alt=""></img></a></Subcontainer2><Subcontainer2><a href="https://t.me/+Ps2rG37khKkyNjI8" target="_blank" rel="noreferrer"><img src={img2} width="40px" alt=""></img></a></Subcontainer2><Subcontainer2><a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer"><img src={img3} width="35px" alt=""></img></a></Subcontainer2> </Container3>
<Menu>
  <MenuItem onClick={enterCitizen}><img src={img5} width="75px" alt=""></img></MenuItem>
  <MenuItem onClick={enterLocation}><img src={img6} width="75px" alt=""></img></MenuItem>  
  <Container4> <Subcontainer><a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer"><img src={img1} width="30px" alt=""></img></a></Subcontainer><Subcontainer><a href="https://t.me/+Ps2rG37khKkyNjI8" target="_blank" rel="noreferrer"><img src={img2} width="35px" alt=""></img></a></Subcontainer><Subcontainer><a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer"><img src={img3} width="35px" alt=""></img> </a></Subcontainer></Container4>
  </Menu>
  
</Container2>
      </NavBar>
            <div className={`secti ${start ? "hiddenav" : ""}`}><Starter></Starter></div>
      <div className={`secti ${mycitizen ? "hiddenav" : ""}`}><Token></Token></div>
     
      <div className={`secti ${loot ? "hiddenav" : ""}`}><Starter></Starter></div>
      <div className={`secti ${location ? "hiddenav" : ""}`}><About></About></div>
      </Section>
      
  )
}

export default Navigation