import React from 'react'
import styled from 'styled-components'
import {useState} from 'react'

import img6 from './assets/textpepe.png';
//Coin contract 0x30697CDb377d5c2864CBd309804F0a92C3E7dcaA
//Game contract 0x5db01422C4A2E67e9887173E337730b120dE137f
// Item contract 0x57086f29eB6ba2eafDb4773B755b0F52f0D282DF

const Section = styled.section`
width: 100vw;
justify-content: center;
align-items: center;
`
const Container = styled.div`
width: 100vw;

margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
background-image: url(${img6});

  background-size: 100%;
  background-position: top;
  @media (min-width: 600px) {
 
    background-size: 100%;
    background-position: top;
  }
  background-repeat: no-repeat;
`
//url(${img6});



const HalfContainer = styled.div`
height: 100vw;
justify-content: center;
align-items: bottom;
margin-bottom: 150px;

  img{
 
width: 100vw;
@media (min-width: 600px) {
 
    width: 60vh;
     
  }
      
`



const Starter = () => {

  const [woPic, setwoPic] = useState(1);

  const handleDecrement = () => {
   
   if (woPic >=5) 
    setwoPic(1);
    if (woPic >=5) return;
    setwoPic(woPic + 1);
  };

/*
  const [data, setData] =useState([]);
  const [holders, setHolders] =useState([]);
  const [supply, setSupply] =useState([]);
  const [volume, setVolume] =useState([]);
  const [sales, setSales] =useState([]);
  const [sales30, set30Sales] =useState([]);
  
    const getData = () => {
    const options = {method: 'GET', headers: {Accept: 'application/json'}};

    fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setData(response.stats.floor_price);
        
      })
      .catch(err => console.error(err))

      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setHolders(response.stats.num_owners);
        
      })
      .catch(err => console.error(err))
    
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setSupply(response.stats.total_supply);
        
      })
      .catch(err => console.error(err))
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setVolume(response.stats.total_volume.toFixed(1));
        
      })
      .catch(err => console.error(err))
     
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setSales(response.stats.total_sales);
        
      })
      .catch(err => console.error(err))
      
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {set30Sales(response.stats.thirty_day_sales);
        
      })
      .catch(err => console.error(err))

    };
    

    
    useEffect(() => {
      getData();
    },[]);
*/

//<img src={require(`./assets/W0${woPic}CL.png`)}
  return(
    <Section>
      <Container>
    
     <HalfContainer onClick={handleDecrement}><img src={require(`./assets/pepe.gif`)} alt=""></img></HalfContainer>
     
      
      
     
  
     
</Container>
</Section>
  )

}

export default Starter