import React from 'react'
import styled from 'styled-components'



import img4 from './assets/pepebuy.png';



const Section = styled.div`
width: 100vw;
color: #FFFFFF;
background-size: 10vh;
background-color: #0153ff;
`



const Container = styled.div`
width: 100vw;
display: flex;
justify-content: center;
align-items: center;
  img{
 
width: 80vw;
@media (min-width: 600px) {
 
  width: 400px;
     
  }
      
`

const WhiteContainer = styled.div`
width: 15rem;
margin: 0 auto;
margin-bottom: 20px;
background-color: #FFFFFF;
border-radius: 20px;
cursor: pointer;
padding: 10px;

img{
width: 100%;
height: auto;
transition: all 0.5s ease;

}
      
`
const Nametext = styled.div`
font-family: "Trebuchet MS", Arial;
text-align: center;
color: #000000;
font-size: 1.5em;
font-weight: 400;
display: flex;
justify-content: center;
align-items: center; 
      
`
const Subtext = styled.div`
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #000000;
display: flex;
justify-content: center;
align-items: center; 
`



const Container2 = styled.div`
margin: 10px;

@media (min-width: 600px) {

  display: flex;
     
  }

      
`
const HalfContainer = styled.div`
width: 100vw;
display: flex;
padding: 15px;
padding-top: 0px;
cursor: pointer;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
@media (min-width: 600px) {
 
  font-size: 1.5em;
  padding: 150px;
padding-top: 0px;
padding-bottom: 10px;
}

  img{
 
width: 40vw;
@media (min-width: 600px) {
 
    width: 30vh;
     
  }
 
  
`


const Token = () => {

 

/*
  const [data, setData] =useState([]);
  const [holders, setHolders] =useState([]);
  const [supply, setSupply] =useState([]);
  const [volume, setVolume] =useState([]);
  const [sales, setSales] =useState([]);
  const [sales30, set30Sales] =useState([]);
  
    const getData = () => {
    const options = {method: 'GET', headers: {Accept: 'application/json'}};

    fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setData(response.stats.floor_price);
        
      })
      .catch(err => console.error(err))

      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setHolders(response.stats.num_owners);
        
      })
      .catch(err => console.error(err))
    
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setSupply(response.stats.total_supply);
        
      })
      .catch(err => console.error(err))
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setVolume(response.stats.total_volume.toFixed(1));
        
      })
      .catch(err => console.error(err))
     
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setSales(response.stats.total_sales);
        
      })
      .catch(err => console.error(err))
      
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {set30Sales(response.stats.thirty_day_sales);
        
      })
      .catch(err => console.error(err))

    };
    

    
    useEffect(() => {
      getData();
    },[]);
*/
  return(
    <Section>
<Container>
   <img src={img4} alt=""></img>
   </Container>
   <HalfContainer>
    
    <a href="https://basescan.org/address/0x7878aFdf850aA8e449320211fad97b89921D41A7" target="_blank" rel="noreferrer" style={{ color: '#FFF' }}>0x7878aFdf850aA8e449320211fad97b89921D41A7</a>
      </HalfContainer>
      <Container2>
      <WhiteContainer><Nametext>Chain</Nametext><Subtext>Base</Subtext></WhiteContainer>
      <WhiteContainer><Nametext>Supply</Nametext><Subtext>1 Billion</Subtext></WhiteContainer>
      <WhiteContainer><Nametext>LP</Nametext><Subtext>90%</Subtext></WhiteContainer>
      <WhiteContainer><Nametext>Tax</Nametext><Subtext>0%</Subtext></WhiteContainer>
      
      
      </Container2>
      
      
     
  
     

</Section>
  )

}

export default Token