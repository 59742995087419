import React from 'react'
import styled from 'styled-components'


import img1 from './assets/crix_vend.png';
import img3 from './assets/robin_vend.png';

import img5 from './assets/peng_low.png';
import img8 from './assets/thetwins.png';

import img10 from './assets/ET.png';
import img11 from './assets/pepe.png';


const Section = styled.div`
width: 100vw;
color: #FFFFFF;
background-size: 10vh;
background-color: #0153ff;
`





const WhiteContainer = styled.div`
width: 15rem;
margin: 0 auto;
margin-bottom: 20px;
background-color: #FFFFFF;
border-radius: 20px;
cursor: pointer;
padding: 10px;

img{
width: 100%;
height: auto;
transition: all 0.5s ease;

}
      
`
const Nametext = styled.div`
font-family: "Trebuchet MS", Arial;
text-align: center;
color: #000000;
font-size: 1.5em;
font-weight: 400;
display: flex;
justify-content: center;
align-items: center; 
      
`
const Subtext = styled.div`
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #000000;
display: flex;
justify-content: center;
align-items: center; 
`

const Container3 = styled.div`
width: 100vw;

justify-content: center;
align-items: center;
display: flex;
  img{

width: 50vw;
@media (min-width: 600px) {
 
  width: 400px;
     
  }
      
`

const Container2 = styled.div`
margin: 10px;

@media (min-width: 600px) {
 
  display: flex;
     
  }

      
`
const HalfContainer = styled.div`
width: 100vw;
display: flex;
padding: 15px;
padding-top: 0px;

font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
@media (min-width: 600px) {
 
  font-size: 1.5em;
  padding: 150px;
padding-top: 0px;
padding-bottom: 10px;
}

  img{
 
width: 40vw;
@media (min-width: 600px) {
 
    width: 30vh;
     
  }
      
`

const HalfContainer2 = styled.div`
width: 100vw;


font-family: "Caveat Brush", Arial;
text-align: center;
font-size: 2.5em;
color: #000000;
justify-content: center;
align-items: center;

@media (min-width: 600px) {
 
  font-size: 3em;
  
}

 
      
`


const About = () => {

 

/*
  const [data, setData] =useState([]);
  const [holders, setHolders] =useState([]);
  const [supply, setSupply] =useState([]);
  const [volume, setVolume] =useState([]);
  const [sales, setSales] =useState([]);
  const [sales30, set30Sales] =useState([]);
  
    const getData = () => {
    const options = {method: 'GET', headers: {Accept: 'application/json'}};

    fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setData(response.stats.floor_price);
        
      })
      .catch(err => console.error(err))

      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setHolders(response.stats.num_owners);
        
      })
      .catch(err => console.error(err))
    
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setSupply(response.stats.total_supply);
        
      })
      .catch(err => console.error(err))
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setVolume(response.stats.total_volume.toFixed(1));
        
      })
      .catch(err => console.error(err))
     
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {setSales(response.stats.total_sales);
        
      })
      .catch(err => console.error(err))
      
      fetch(`https://api.opensea.io/api/v1/collection/all-in-one-out/stats`, options)
      .then(response => response.json())
      .then(response => {set30Sales(response.stats.thirty_day_sales);
        
      })
      .catch(err => console.error(err))

    };
    

    
    useEffect(() => {
      getData();
    },[]);
*/
  return(
    <Section>

   <HalfContainer2>
   <img src={img11} width="300px" alt=""></img> 
      </HalfContainer2>
     <HalfContainer>
      a memecoin powered by Evil Twins Collective. PepeWifKnife is a meme coin without utility. Just fucking around on base for fun. Buy at own risk.  
      </HalfContainer>
     
      <Container3>
   <img src={img5} width="300px" alt=""></img>
   </Container3>
      <HalfContainer2>
      <img src={img10} width="350px" alt=""></img>
      </HalfContainer2>
      <HalfContainer>
      Evil Twins collective are just two bothers, throwing shit on the wall and see what sticks. No drop belongs to a "project" with utility, just some jpegs and coins on the chain. 
      </HalfContainer>
      <HalfContainer2>
      <img src={img8} width="300px" alt=""></img>
      </HalfContainer2>
      <Container2>
      <WhiteContainer><img src={img3} alt=""></img><Nametext>Robin Karlsson</Nametext><Subtext>Developer</Subtext></WhiteContainer>
      <WhiteContainer><img src={img1} alt=""></img><Nametext>Dr.Crix</Nametext><Subtext>Artist</Subtext></WhiteContainer>
      
      </Container2>
      
      
     
  
     

</Section>
  )

}

export default About